<template>
    <v-container>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="telefone"
            v-mask="'(##) ####-#####'"
            label="Telefone"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="email"
            label="E-mail"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            v-model="nome_contato"
            label="Contato"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'RealEstateContactForm',
  computed: {
    ...mapFields([
      'realState.telefone',
      'realState.email',
      'realState.nome_contato'
    ])
  }
}
</script>
