<template>
    <v-container>
      <v-row>
        <v-col md="1">
          <v-text-field
            v-model="codigo"
            label="Código"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col md="5">
          <v-text-field
            v-model="nome"
            label="Nome"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="cpf_cnpj"
            v-mask="'##.###.###/####-##'"
            label="CNPJ"
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-text-field
            v-model="creci"
            label="CRECI"
          ></v-text-field>
        </v-col>
        <v-col  md="2">
          <generated-condition-term :realEstateId="this.id" v-if="isRealState"/>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import GeneratedConditionTerm from './GeneratedConditionTerm.vue'

export default {
  name: 'RealEstateIdentificationForm',
  components: {
    GeneratedConditionTerm
  },
  props: {
    isRealState: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    rules: {
      required: (v) => !!v || 'Campo obrigatório'
    }
  }),
  computed: {
    ...mapFields([
      'realState.id',
      'realState.codigo',
      'realState.nome',
      'realState.cpf_cnpj',
      'realState.creci',
      'realState.logo_url'
    ])
  }
}
</script>
