<template>
  <div>
    <v-row>
      <v-col>
        <v-file-input
          ref="uploader"
          multiple
          label="File input"
          truncate-length="50"
          class="hidden"
          @change="selectFiles"
          v-if="!readonly"
        ></v-file-input>
        <v-btn
          color="primary"
          @click="onUploadBtnClick"
          outlined
          :loading="savingFiles"
          v-if="!readonly"
          rounded
        >
          Selecionar arquivos...
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      default: []
    },
  },
  data: () => ({
    savingFiles: false,
  }),
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onUploadBtnClick() {
      this.$refs.uploader.$refs.input.click();
    },
    selectFiles(files) {
      this.value.push(...files)
    },
  },
};
</script>

<style>
.hidden {
  display: none;
}
.border-outset {
  border: outset;
}
</style>
