<template>
  <RealEstateTable></RealEstateTable>
</template>

<script>
import RealEstateTable from '../../components/RealEstate/Table'

export default {
  components: {
    RealEstateTable,
  },
};
</script>

<style>
</style>
