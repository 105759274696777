<template>
  <v-dialog v-model="generateDialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="primary" title="Gerar Termos e Condições" v-bind="attrs" v-on="on">
          Credenciamento
          <v-icon>mdi-file-document</v-icon>
        </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
            <v-col>
              <h5 class="text_success"> Termos e Condições </h5>
            </v-col>
            <v-col align="right">
              <v-btn color="black" right icon dark :disabled=loading @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-card-text align="center" justify="center">
        <v-container>
          <v-progress-circular :size="70" :width="7" color="primary" indeterminate
              v-if=loading>
          </v-progress-circular>
          <v-row v-else>
            <v-col v-if="hasNoDocuments">
              <v-btn color="primary" rounded @click="generateConditionTerm">
                Gerar Termos e Condições
                <v-icon>mdi-file-document</v-icon>
              </v-btn>
            </v-col>
            <v-col v-else v-for="(item, index) in realEstateConditionTerm" :key="index" cols="12">
              <v-btn :href="item" target="_blank" rounded>
                Baixar documento
                <v-icon>mdi-floppy</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'GeneratedConditionTerm',
  props: {
    realEstateId: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    generateDialog: false,
    loading: false,
    hasDocuments: false
  }),

  computed: {
    ...mapGetters(['realEstateConditionTerm']),

    hasNoDocuments () {
      return this.hasDocuments === false
    }
  },

  methods: {
    ...mapActions([
      'getrealEstateConditionTerm'
    ]),
    generateConditionTerm () {
      this.loading = true
      const payload = {
        realEstate_id: this.realEstateId
      }
      this.getrealEstateConditionTerm(payload).then(() => {
        this.hasDocuments = true
      }).catch(err => {
        this.$toastr.e('Falha ao gerar Termos e Condições \n' + err.response.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
    closeDialog () {
      this.hasDocuments = false
      this.generateDialog = false
    }
  }
}
</script>
