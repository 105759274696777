<template>
  <div>
    <v-card elevation="6" class="pa-3">
      <v-subheader class="ma-0 pa-0">
        <h4 class="bold primary--text">Imobiliárias</h4>
      </v-subheader>
      <br />
      <v-data-table
        v-if=table
        :headers="headers"
        :items="tableContent"
        sort-by="name"
        class="elevation-0"
        :items-per-page="10"
        no-data-text="Nenhum dado encontrado"
        no-results-text="Nenhum resultado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10, 15, 20, -1],
          'page-text': '{0}-{1} de {2}',
        }"
        :search="search"
        :loading="loading"
        loading-text="Carregando dados..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <RealEstateFormDialog v-model=search :realStateId=itemId @cleanData=resetItemId @reloadList=reloadTable></RealEstateFormDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editItem(item.id)" title="Editar">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import RealEstateFormDialog from './FormDialog'

export default {
  name: 'RealEstateTable',
  components: {
    RealEstateFormDialog
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Código', value: 'codigo' },
      { text: 'Nome', value: 'nome' },
      { text: 'CNPJ', value: 'cpf_cnpj' },
      { text: 'Endereço', value: 'endereco' },
      { text: 'Telefone', value: 'telefone' },
      { text: 'CRECI', value: 'creci' },
      { text: 'E-mail', value: 'email' },
      { text: 'Contato', value: 'nome_contato' },
      { text: 'Ações', value: 'actions', sortable: false }
    ],
    loading: false,
    itemId: null,
    table: true
  }),
  computed: {
    ...mapGetters([
      'realStateList'
    ]),
    tableContent () {
      return this.realStateList
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'getRealStateList'
    ]),
    initialize () {
      this.loading = true
      this.getRealStateList()
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setSearch (val) {
      this.search = val
    },
    editItem (id) {
      this.itemId = id
    },
    resetItemId () {
      this.itemId = null
    },
    reloadTable () {
      this.initialize()
    }
  }
}
</script>
