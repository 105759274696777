<template>
  <div>
    <FileInput v-model=files></FileInput>
    <v-row class="mb-4" v-show="files.length">
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Ações</th>
                <th class="text-left">Nome do arquivo</th>
                <th class="text-left">Classificar documento</th>
                <th class="text-left">Observações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, i) in files" :key="i">
                <td>
                  <v-btn class="ma-0 pa-0" text icon @click="showFile(file)" v-if="isSavedFile(file)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn v-if="isSavedFile(file)"
                    class="ma-0 pa-0"
                    text
                    icon
                    @click="downloadFile(file)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-0 pa-0"
                    text
                    icon
                    @click="removeFile(file, i)"
                    v-if="!readonly"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
                <td>{{ file.name }}</td>
                <td>
                  <v-select
                    no-data-text="Nenhum dado encontrado"
                    item-text="descricao"
                    item-value="id"
                    dense
                    v-model="file_types[i]"
                    :items="op_tipo_documento"
                    class="ma-0 pa-0"
                    hide-details
                    required
                    :readonly="readonly"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    dense
                    v-model="observations[i]"
                    class="ma-0 pa-0"
                    hide-details
                    :readonly="readonly"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import FileInput from '@/components/Generics/NewFileInput'

export default {
  name: 'RealEstateDocumentsForm',
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileInput
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapFields([
      'realState.documentos.files',
      'realState.documentos.file_types',
      'realState.documentos.observations'
    ]),
    ...mapGetters([
      'documentTypeList'
    ]),
    op_tipo_documento () {
      return this.documentTypeList.filter(d => {
        return d.referencia == 2
      })
    }
  },
  created () {
    this.getDocumentTypeList()
  },
  methods: {
    ...mapActions([
      'getDocumentTypeList',
      'deleteRealStateDocument',
      'downloadRealStateDocument',
      'showRealStateDocument'
    ]),
    removeFile (file, i) {
      if (this.isSavedFile(file)) {
        this.deleteRealStateDocument(file).then((resp) => {
          file.is_excluido = true
          this.files.splice(i, 1)
          this.$toastr.s(resp.message)
        })
      } else {
        this.files.splice(i, 1)
        this.$toastr.s('Documento removido com sucesso')
      }
    },
    downloadFile (file) {
      this.downloadRealStateDocument(file.id).then((resp) => {
        this.fileDownload(resp, file.name)
      })
    },
    showFile (file) {
      this.showRealStateDocument(file.id).then((resp) => {
        this.fileShow(resp)
      })
    },
    isSavedFile (file) {
      return !(file.type !== undefined)
    }
  }
}
</script>
