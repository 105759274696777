<template>
  <v-container>
    <v-row>
      <v-col md="3" class="align-self-md-center pa-6">
        <v-row>
          <v-text-field
            v-model="cep"
            v-mask="'#####-###'"
            label="CEP"
            :loading="loadingAddress"
            :readonly=readonly
          ></v-text-field>
          <v-btn
            title="Consultar CEP"
            outlined
            class="pa-0 mt-3 ml-2"
            rounded
            color="primary"
            @click="getAddress"
            :loading="loadingAddress"
            v-if=!readonly
          >
            <v-icon>mdi-magnify</v-icon>
            CEP
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="logradouro"
          label="Endereço"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field
          v-model="numero"
          label="Número"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="complemento"
          label="Complemento"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="bairro"
          label="Bairro"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="nome"
          item-value="id"
          v-model="estado_id"
          :items="op_estados"
          label="Estado"
          @change="getCities"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="nome"
          item-value="id"
          v-model="cidade_id"
          :items="op_cidades"
          label="Cidade"
          :loading="loadingAddress || loadingCities"
          :readonly=readonly
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RealEstateAddressForm',
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingCities: false,
    loadingAddress: false
  }),
  computed: {
    ...mapFields([
      'realState.cep',
      'realState.logradouro',
      'realState.numero',
      'realState.complemento',
      'realState.bairro',
      'realState.estado_id',
      'realState.cidade_id'
    ]),
    ...mapGetters(['statesList', 'citiesList']),
    op_estados () {
      return this.statesList
    },
    op_cidades () {
      return this.citiesList
    }
  },
  created () {
    this.getStatesList().then(() => {
      if (this.estado_id) {
        this.getCities(this.estado_id)
      }
    })
  },
  methods: {
    ...mapActions(['getStatesList', 'getCitiesList', 'getAdrressByCep']),
    getCities (val) {
      this.loadingCities = true
      this.getCitiesList(val).then(() => {
        this.loadingCities = false
      })
    },
    getAddress () {
      this.loadingAddress = true
      this.getAdrressByCep(this.cep)
        .then((resp) => {
          this.bairro = resp.bairro
          this.complemento = resp.complemento
          this.bairro = resp.bairro
          this.logradouro = resp.logradouro
          this.estado_id = this.statesList.find((state) => {
            return state.uf.substring(0, 2) == resp.uf
          }).id
          this.loadingCities = true
          this.getCitiesList(this.estado_id).then(() => {
            this.cidade_id = this.citiesList.find((city) => {
              return resp.ibge.substring(0, 6) == city.ibge
            }).id
            this.loadingCities = false
          })
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingAddress = false
        })
    }
  }
}
</script>
