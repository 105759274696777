<template>
  <v-dialog v-model="dialog" max-width="90%" @click:outside=closeDialog>
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col md="4">
          <v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="right" v-if=!readonly>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            rounded
          >
            Nova imobiliária
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <h5 class="bold primary--text">{{ hasRealStateId ? 'Imobiliária' : 'Nova imobiliária' }}</h5>
          </v-col>
          <v-col align="right">
            <v-btn color="black" right icon dark @click=closeDialog>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if=loading>
        <v-container>
          <v-row justify="center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-else>
      <v-form v-model="valid">
        <v-container>
          <v-row justify="center">
            <v-expansion-panels v-model="openedPanel" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row>
                    <v-col md="2" class="pa-0">
                      <span class="ml-1"> Identificação</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <RealEstateIdentificationForm :isRealState="hasRealStateId"></RealEstateIdentificationForm>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row>
                    <v-col md="2" class="pa-0">
                      <span class="ml-1"> Endereço</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <RealEstateAddressForm></RealEstateAddressForm>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row>
                    <v-col md="2" class="pa-0">
                      <span class="ml-1"> Contato</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <RealEstateContactForm></RealEstateContactForm>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row>
                    <v-col md="2" class="pa-0">
                      <span class="ml-1"> Documentos</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <RealEstateDocumentsForm></RealEstateDocumentsForm>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click=save
          :loading=saving
          rounded
          :disabled=!valid
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import RealEstateAddressForm from './Forms/Address'
import RealEstateIdentificationForm from './Forms/Identification'
import RealEstateContactForm from './Forms/Contact'
import RealEstateDocumentsForm from './Forms/Documents'

export default {
  name: 'RealEstateFormDialog',
  components: {
    RealEstateAddressForm,
    RealEstateIdentificationForm,
    RealEstateContactForm,
    RealEstateDocumentsForm
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    realStateId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    openedPanel: 0,
    saving: false,
    loading: false,
    search: null,
    valid: false
  }),
  watch: {
    realStateId (val) {
      this.resetRealState()

      if (this.exists(val)) {
        this.dialog = true
        this.loading = true
        this.getRealState(val)
          .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    search (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    hasRealStateId () {
      return this.realStateId !== null
    }
  },
  methods: {
    ...mapMutations(['resetRealState']),
    ...mapActions(['saveRealState', 'getRealState']),
    save () {
      this.saving = true
      this.saveRealState()
        .then(() => {
          this.resetRealState()
          this.$emit('reloadList')
          this.closeDialog()
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.saving = false
        })
    },
    closeDialog () {
      this.resetRealState()
      this.$emit('cleanData')
      this.dialog = false
      return true
    }
  }
}
</script>
